import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/introduction-to-gulp",
  "date": "2015-09-03",
  "title": "INTRODUCTION TO GULP",
  "author": "admin",
  "tags": ["development", "javascript", "gulp"],
  "featuredImage": "feature.jpg",
  "excerpt": "Gulp is a JavaScript task runner. It’s an alternative to Grunt, which allowed you to define your tasks using JavaScript inside of a file called Gruntfile.js. In this tutorial, I’ll show you how to install Gulp and create a simple configuration file to get your feet wet with this great new task runner."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why Gulp?`}</h2>
    <p>{`Compared to `}<inlineCode parentName="p">{`Grunt`}</inlineCode>{`, Gulp runs much faster. Tasks get crunched quicker, and the time you spend between writing code and seeing the changes in the browser is significantly less. Why is this? According to Gulp, it uses the power of node streams, giving you fast builds that don't write intermediary files to disk.`}</p>
    <h2>{`Installing Gulp`}</h2>
    <p>{`Gulp.js comes packaged as a `}<inlineCode parentName="p">{`Node`}</inlineCode>{` module making it incredibly easily to install using `}<inlineCode parentName="p">{`NPM`}</inlineCode>{`(Node Package Manager). To install Gulp, open up your terminal and type in the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install -g gulp
`}</code></pre>
    <p>{`This pulls Gulp from the npm registry and configures it globally on your system so you can access via command-line.`}</p>
    <h2>{`Setting Up A Gulp Project`}</h2>
    <p>{`If you don't have `}<inlineCode parentName="p">{`node`}</inlineCode>{` set up on your machine, go ahead and do so. The npm command line tool comes bundled with node, so you'll be ready to roll after this. Next, create a new project directory, and cd into it. Let's initialise our directory by running the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm init
`}</code></pre>
    <p>{`Before installing Gulp, there are few things that need to be done inside your project's directory.`}</p>
    <h1>{`Install two dependencies packages`}</h1>
    <h1>{`Install any plugins you'd like to use`}</h1>
    <h1>{`Create a `}<strong parentName="h1">{`Gulp.js`}</strong>{` file to define the tasks you'd like to run`}</h1>
    <p>{`First, let's install the dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install gulp gulp-util --save-dev
`}</code></pre>
    <p>{`Now, we'll need to install the Gulp plugins that will perform the tasks that we define in our configuration file. These plugins are also Node package modules so we'll use npm again to install them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install gulp-uglify gulp-concat --save-dev
`}</code></pre>
    <p>{`In this case, we are  installing two plugins which will minify/compress JavaScript code using the `}<inlineCode parentName="p">{`Uglify.js`}</inlineCode>{` compressor and concatenate all JavaScipt files into a single source file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "devDependencies": {
        "gulp-util": "~2.2.13",
        "gulp": "~3.5.0",
        "gulp-uglify": "~0.2.0",
        "gulp-concat": "~2.1.7"
    }
}
`}</code></pre>
    <p>{`Gulp offers over 200 plugins for all types of functionality including:`}</p>
    <h1>{`LiveReload`}</h1>
    <h1>{`JSHint`}</h1>
    <h1>{`Sass`}</h1>
    <h1>{`CoffeeScript file compilation`}</h1>
    <h2>{`Configure Gulp.js File`}</h2>
    <p>{`Create a `}<strong parentName="p">{`gulpfile.js`}</strong>{` at the root of your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var gulp = require('gulp');

gulp.task('default', function() {
    // code for default task
});
`}</code></pre>
    <p>{`Run Gulp:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`gulp
`}</code></pre>
    <p>{`There you go, we're ready to begin using gulp. At this point, we can successfully run gulp from the command line, and all will be well. It's a bit useless at this point though, so let's set up some dummy files and implement some tasks.`}</p>
    <p>{`First, let's configure our directory structure like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  gulpfile.js
  package.json
  src/
    js/
  dist/
    js/
    css/
`}</code></pre>
    <p>{`In the `}<strong parentName="p">{`src/js`}</strong>{` directory, create two JavaScript files and populate them like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function helloWorld() {
    console.log("Hello World!");
}

function goodbyeWorld() {
    console.log("Goodbye World!");
}
`}</code></pre>
    <p>{`Now lets tell Gulp which plugins are required to complete the defined tasks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var gulp = require('gulp'),
    gutil = require('gulp-util'),
    uglify = require('gulp-uglify'),
    concat = require('gulp-concat');
`}</code></pre>
    <p>{`Next up, we need to define the tasks that will be run by Gulp. In my case, I'm looking to do two things:`}</p>
    <h1>{`Compress images`}</h1>
    <h1>{`Minify my JavaScript files`}</h1>
    <p>{`Defining a task is for all intents a JavaScript call. We use the Gulp method `}<strong parentName="p">{`task()`}</strong>{` to define the task we want to run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`gulp.task('js', function () {
    gulp.src('./js/*.js')
        .pipe(uglify())
        .pipe(concat('all.js'))
        .pipe(gulp.dest('./js'));
});
`}</code></pre>
    <p>{`Taking this a step further, Gulp also provides another method called `}<strong parentName="p">{`watch()`}</strong>{` which provides a means for automatically checking a specific resource for changes. This allows for great automation of tasks versus constantly having to manually type in `}<strong parentName="p">{`gulp`}</strong>{` at the command line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`gulp.watch('./js/*', function () {
    gulp.run('js');
});
`}</code></pre>
    <p>{`When this runs, Gulp will continue to monitor the specified directly for changes to files and if a change occurs, will re-run the `}<strong parentName="p">{`js`}</strong>{` task to `}<inlineCode parentName="p">{`minify`}</inlineCode>{` and `}<inlineCode parentName="p">{`concatenate`}</inlineCode>{` the JavaScript files. Awesome!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      